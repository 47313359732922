import React, { useState, useEffect, useRef } from 'react';
import { database } from '../firebase';
import { ref, push, onValue } from "firebase/database";
import './ChatWindow.css';
import SpotlightButton from './SpotlightButton';
import { useAppState } from '../AppStateContext'; // Import the useAppState hook

function ChatWindow() {
    const messagesEndRef = useRef(null);
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const { selectedConversation, togglePapaVisibility } = useAppState(); // Assume there's selectedConversation in your app state
    const chatPartner = selectedConversation ? selectedConversation : 'Select a conversation';

    useEffect(() => {
        console.log('selectedConversation:', selectedConversation);
        const messagesRef = ref(database, `messages/${selectedConversation}`);

        const unsubscribe = onValue(messagesRef, (snapshot) => {
            const msgs = snapshot.val();
            const messagesList = [];
            for (let id in msgs) {
                messagesList.push({ id, ...msgs[id] });
            }
            setMessages(messagesList);
        });

        return () => unsubscribe();
    }, [selectedConversation]);

    useEffect(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [messages]);

    const sendMessage = () => {
        if (newMessage.trim()) {
            const newMessageRef = ref(database, `messages/${selectedConversation}`);

            push(newMessageRef, {
                name: 'Nurse / Caregiver',
                message: newMessage,
                time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
            });

            setNewMessage('');
        }
    };

    const handleInputChange = (e) => {
        setNewMessage(e.target.value);
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            sendMessage();
        }
    };

    return (
        <div className="chat-window">
            <div className="chat-header">
                <h3 className="chat-partner-name">{chatPartner}</h3>
            </div>
            <div className="chat-messages">
                {messages.map((msg) => (
                    <div key={msg.id} className={`chat-message ${msg.name === 'Nurse / Caregiver' ? 'right' : ''}`}>
                        <div className="message-content">{msg.message}</div>
                        <div className="message-meta">
                            <span className="message-author">{msg.name}</span>
                            <span className="message-time">{msg.time}</span>
                        </div>
                    </div>
                ))}
                <div ref={messagesEndRef} />
            </div>
            {/* <div className="toggle-button-container" onClick={togglePapaVisibility}>
                <SpotlightButton />
            </div> */}
            <div className="chat-input">
                <textarea
                    placeholder="Type a message..."
                    value={newMessage}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyPress}
                ></textarea>
                <button onClick={sendMessage} className="chat-send-button">Send</button>
            </div>
        </div>
    );
}

export default ChatWindow;
