import React, { useState, useEffect, useCallback } from 'react';
import './PAPA.css';
import Card from './Card';
import MainHeader from './MainHeader';
import { useAppState } from '../AppStateContext';
import { database } from '../firebase';
import { ref, onValue, set } from "firebase/database";

const PAPA = () => {
    const [cardsData, setCardsData] = useState([]);
    const [visibleCardsCount, setVisibleCardsCount] = useState(1);
    const [selectedFilters, setSelectedFilters] = useState(["Resource", "Program"]);
    const [selectedSidebarButton, setSelectedSidebarButton] = useState('all');
    const [selectedSection, setSelectedSection] = useState('Spotlight');

    const { selectedConversation } = useAppState();

    const setCardsDataFromFirebase = useCallback(() => {
        const messagesRef = ref(database, `PAPA/${selectedConversation}`);
        const unsubscribe = onValue(messagesRef, (snapshot) => {
            const data = snapshot.val();
            if (data) {
                // Ensure that the data is an array and filter out any undefined entries
                const dataArray = Object.values(data).filter(item => item !== undefined);
                setCardsData(dataArray);
            } else {
                // Default data if none is found in Firebase
                setCardsData([
                    { id: 1, type: "Resource", impact: true, kind: "Please Select", saved: false, title: "Select a conversation first", tags: [], link: "https://peersupport.io" }
                ]);
            }
        });
        return () => unsubscribe();
    }, [selectedConversation]);

    useEffect(() => {
        const unsubscribe = setCardsDataFromFirebase();
        return () => unsubscribe();
    }, [selectedConversation, setCardsDataFromFirebase]);

    const handleFilterChange = (filter) => {
        setSelectedFilters(prev => {
            return prev.includes(filter) ? prev.filter(f => f !== filter) : [...prev, filter];
        });
    };

    const handleSidebarButtonSelect = (button) => {
        setSelectedSidebarButton(button);
        setVisibleCardsCount(1); // Reset visible cards count when switching modes
        setSelectedSection(button === 'saved' ? 'Saved' : 'Spotlight');
    };

    const toggleSave = (cardid) => {
        if (cardsData.length === 1 && cardsData[0].title === "Select a conversation first") {
            console.log("Default data is presented, toggleSave is disabled.");
            return;  // This will exit the function without making any changes or attempting to update Firebase
        }

        let updatedCard;
        const updatedCardsData = cardsData.map((card) => {
            if (card.id === cardid) {
                updatedCard = { ...card, saved: !card.saved };
                return updatedCard;
            }
            return card;
        });
        setCardsData(updatedCardsData);

        if (updatedCard) {
            // Assuming each card's data is stored under its ID within `messages/{selectedConversation}/PAPA/{cardid}`
            const specificCardRef = ref(database, `PAPA/${selectedConversation}/${cardid}`);

            // Using set() to replace the data at this node, only updating the 'saved' status
            set(specificCardRef, updatedCard)
                .then(() => {
                    console.log("Card updated successfully in Firebase:", cardid);
                })
                .catch(error => {
                    console.error("Error updating card in Firebase:", error);
                });
        } else {
            console.error('No card found with id:', cardid);
        }
    };

    const toggleLink = (link) => {
        window.open(link, '_blank');
    };

    const filteredCards = cardsData.filter(card =>
        (selectedSidebarButton === 'saved' ? card.saved : true) && selectedFilters.includes(card.type)
    );

    return (
        <div className="top-resource">
            <div className="drawing-border"></div>
            <div className="PAPA-Bulk">
                <div className="PAPA-Sidebar">
                    <div className={`sidebar-button ${selectedSidebarButton === 'all' ? 'selected' : ''}`} onClick={() => handleSidebarButtonSelect('all')}>
                        <AllCardsIcon />
                    </div>
                    <div className={`sidebar-button ${selectedSidebarButton === 'saved' ? 'selected' : ''}`} onClick={() => handleSidebarButtonSelect('saved')}>
                        <SavedCardsIcon />
                    </div>
                </div>
                <div className="PAPA-Content">
                    <MainHeader onFilterChange={handleFilterChange} selectedFilters={selectedFilters} selectedSection={selectedSection} />
                    <div className="resource-cards">
                        {filteredCards.slice(0, visibleCardsCount).map(card => (
                            <Card key={card.id} {...card} toggleSave={toggleSave} toggleLink={toggleLink} />
                        ))}
                        {visibleCardsCount < filteredCards.length && (
                            <div className="reload" onClick={() => setVisibleCardsCount(prev => prev + 1)}>
                                <img src="reloadicon.png" alt="Load More" />
                                <span>Load more</span>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

const AllCardsIcon = () => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
            <path d="M11.5675 5.93702L9.31481 8.18891C8.07144 9.43305 6.05439 9.43305 4.81102 8.18891C4.61507 7.99373 4.46189 7.77367 4.32735 7.54661L5.374 6.49997C5.42376 6.4498 5.4852 6.42104 5.5439 6.38722C5.61621 6.63451 5.74256 6.86856 5.93697 7.06294C6.55787 7.68424 7.56836 7.68347 8.18886 7.06294L10.4408 4.81105C11.0621 4.18975 11.0621 3.17965 10.4408 2.55875C9.82023 1.93785 8.81016 1.93785 8.18886 2.55875L7.38794 3.36045C6.73827 3.10735 6.03804 3.03932 5.35648 3.13922L7.06289 1.43281C8.30703 0.189064 10.3233 0.189064 11.5675 1.43281C12.8108 2.6766 12.8108 4.69328 11.5675 5.93702ZM5.61232 9.6399L4.81102 10.4416C4.19012 11.0621 3.17963 11.0621 2.55873 10.4416C1.93783 9.8203 1.93783 8.81021 2.55873 8.18894L4.81102 5.93704C5.43232 5.31574 6.44202 5.31574 7.06292 5.93704C7.25693 6.13105 7.38328 6.3651 7.45639 6.61199C7.51549 6.57778 7.57615 6.54978 7.62591 6.50002L8.67256 5.45377C8.53882 5.22594 8.38484 5.00665 8.18889 4.8111C6.94552 3.56735 4.92847 3.56735 3.6847 4.8111L1.43281 7.06299C0.189064 8.30713 0.189064 10.3234 1.43281 11.5676C2.67655 12.8109 4.69323 12.8109 5.93699 11.5676L7.64381 9.86074C6.96184 9.96104 6.26162 9.89261 5.61232 9.6399Z" fill="url(#paint0_linear_4145_1359)" />
            <defs>
                <linearGradient id="paint0_linear_4145_1359" x1="10.4201" y1="2.94749" x2="3.65807" y2="8.33547" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#79BBE0" />
                    <stop offset="0.620958" stop-color="#C87FC0" stop-opacity="0.647762" />
                    <stop offset="1" stop-color="#FF7878" stop-opacity="0.4" />
                </linearGradient>
            </defs>
        </svg>
    );
};

const SavedCardsIcon = () => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="11" height="13" viewBox="0 0 11 13" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M1.5 2.63416C1.5 2.08187 1.94772 1.63416 2.5 1.63416H8.5C9.05228 1.63416 9.5 2.08187 9.5 2.63416V10.8621C9.5 11.2844 9.00893 11.5165 8.68263 11.2485L6.13474 9.15555C5.76583 8.85252 5.23417 8.85252 4.86526 9.15555L2.31737 11.2485C1.99107 11.5165 1.5 11.2844 1.5 10.8621V2.63416ZM2.5 0.134155H8.5C9.88071 0.134155 11 1.25344 11 2.63416V10.8621C11 12.5512 9.03571 13.4797 7.73052 12.4075L5.5 10.5753L3.26948 12.4075C1.96429 13.4797 0 12.5512 0 10.8621V2.63416C0 1.25345 1.11929 0.134155 2.5 0.134155Z" fill="#686EA5" />
            <defs>
                <linearGradient id="paint0_linear_4221_905" x1="-1.92857" y1="13.6342" x2="10.6429" y2="0.491297" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FDB8C1" />
                    <stop offset="0.55" stop-color="#DAA2D2" />
                    <stop offset="1" stop-color="#7ABCE1" />
                </linearGradient>
            </defs>
        </svg>
    );
};

export default PAPA;
