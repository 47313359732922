import React, { createContext, useContext, useState } from 'react';

const AppStateContext = createContext();

export const useAppState = () => useContext(AppStateContext);

export const AppStateProvider = ({ children }) => {
  const [isPapaVisible, setIsPapaVisible] = useState(false);
  const [selectedConversation, setSelectedConversation] = useState(null);

  const togglePapaVisibility = () => {
    setIsPapaVisible(prev => !prev);
  };

  return (
    <AppStateContext.Provider value={{
      isPapaVisible,
      togglePapaVisibility,
      selectedConversation,
      setSelectedConversation // Provide the setter function for updating the selected conversation
    }}>
      {children}
    </AppStateContext.Provider>
  );
};
