// ChatApp.js
import React from 'react';
import AppRoutes from './AppRoutes'; // Import the routing component
import Sidebar from './components/Sidebar';
import ContactList from './components/ContactList';
import ChatWindow from './components/ChatWindow';
import InfoPanel from './components/InfoPanel';
import TopBar from './components/TopBar';
import PAPA from './components/PAPA';
import './screens/ChatApp.css';
import { useAppState } from './AppStateContext'; // Import useAppState

function App() {
  return <AppRoutes />; // Render the AppRoutes component
}

export default App;
